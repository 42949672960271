<template>
  <div>
    <div id="outPassenger" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <div class="N-Page T-size-24">Outbound Passengers</div>
        <div class="b-numPassenger">
          <span class="icon I-passengers"></span>
          <div class="T-size-20 SemiBold">{{ sumPassenger }} Passengers</div>
        </div>
      </div>

      <!-- Revise -->
      <!-- <div class="box-S4 wrap-Main B-passenger"  v-if="total > 0">
        <div class="box-S4 flex-between-center">
          <div>
            <div style="margin-bottom: 10px; color: #fb0000 !important">
              <v-icon style="color: #fb0000 !important"
                >mdi-account-alert</v-icon
              >
              Warning Different {{ total }} Name (Booking Now)
            </div>
          </div>
          <v-btn
            class="right"
            flat
            color="primary"
            @click="syncDataOut()"
            style="margin-top: -10px"
          >
            <v-icon>mdi-sync</v-icon>Sync Passenger
          </v-btn>
        </div>
        <div class="box-S4 noPadding">
          <v-data-table
            :headers="headersRevise"
            :items="dataCompareOut"
            hide-actions
            class="test"
          >
            <template slot="headerCell" slot-scope="{ header }">
              <span
                class="subheading font-weight-light text--darken-3"
                v-text="header.text"
              />
            </template>
          </v-data-table>
        </div>
      </div> -->

      <!-- showAsGrid GRID -->
      <div class="box-S4" v-if="showAsGrid">
        <v-expansion-panels accordion class="b-accod" v-model="panel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="panal-title T-size-20">Passenger List</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="wrap-passenger">
                <div class="box-S1-3" v-for="(item, index) in listPassenger" :key="item.IdPass">
                  <div class="B-passenger" :class="item.retire" v-show="
                    item.MedicalCheckupDayLeft > '90' &&
                    item.SafetyTrainingDayLeft > '90'
                  ">
                    <!-- เพิ่ม Class update / delete เมื่อมีการส่งค่าว่าโดนแก้ไข หรือ ลบ -->
                    <div>
                      <div class="b-person">
                        <div class="b-display">
                          <div class="B-display person1" v-if="
                            item.u_imageurl == undefined ||
                            item.u_imageurl == null
                          "></div>
                          <div class="B-display person1" :style="{
                            backgroundImage:
                              'url(' + item.u_imageurl + ') !important',
                          }"></div>
                          <!-- เพิ่ม Class warning1 / warning2 เมื่อมี warning -->
                        </div>
                        <div class="b-name-company">
                          <p class="t-name T-size-14">{{ item.FullName }}</p>
                          <div class="t-company T-size-12" v-if="customer != undefined">
                            Customers : {{ customer }}
                          </div>
                        </div>
                        <div class="b-action">
                          <div class="b-btn">
                            <v-btn fab class="btn-edit" @click="EditItem(item, index)" :disabled="
                              readonly_eng ||
                              readonly_pilot ||
                              readonly_shore ||
                              readonly_finance ||
                              readonly_management
                            ">
                              <span class="I-edit"></span>
                            </v-btn>
                            <v-btn fab class="primary btn-delete" @click="DeleteItem(item)" :disabled="
                              readonly_eng ||
                              readonly_pilot ||
                              readonly_shore ||
                              readonly_finance ||
                              readonly_management
                            ">
                              <span class="I-bin"></span>
                            </v-btn>
                          </div>
                        </div>
                      </div>
                      <div class="b-detail">
                        <div class="t-title">Destiantion</div>
                        <div class="box-S4 flex-between-center t-detail">
                          <div class="bL">
                            <span class="T-size-12">Departure From</span>
                            <p class="T-size-16">{{ item.departurefrom }}</p>
                          </div>
                          <div class="bC" v-if="item.checkboxinterfield == true">
                            <span class="T-size-12">Inter Field</span>
                            <i class="I-check"></i>
                          </div>
                          <div class="bR">
                            <span class="T-size-12">Destination</span>
                            <p class="T-size-16">{{ item.destination }}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="b-recover">
                      <v-btn class="theme-btn-even btn-recover" @click="Recover(item, index)" :disabled="
                        readonly_eng ||
                        readonly_pilot ||
                        readonly_shore ||
                        readonly_finance ||
                        readonly_management
                      ">
                        <span class="T-size-18">Recover</span>
                      </v-btn>
                    </div>
                  </div>

                  <div class="B-passenger expired" :class="item.retire" v-show="
                    item.MedicalCheckupDayLeft <= '90' ||
                    item.SafetyTrainingDayLeft <= '90'
                  ">
                    <!-- เพิ่ม Class update / delete เมื่อมีการส่งค่าว่าโดนแก้ไข หรือ ลบ -->
                    <div>
                      <div class="b-person">
                        <div class="b-display">
                          <div class="B-display person1" v-if="
                            item.u_imageurl == undefined ||
                            item.u_imageurl == null
                          "></div>
                          <div class="B-display person1" v-else :style="{
                            backgroundImage:
                              'url(' + item.u_imageurl + ') !important',
                          }"></div>
                          <!-- เพิ่ม Class warning1 / warning2 เมื่อมี warning -->
                        </div>
                        <div class="b-name-company">
                          <p class="t-name T-size-14">{{ item.FullName }}</p>
                          <!-- Add -->
                          <div class="red--text" v-show="item.o_name === 'CPOC'">
                            <v-icon class="subheading T-size-18" v-show="
                              $CheckWarning.wranningSafetyTraining(
                                item.SafetyTrainingDayLeft
                              )
                            " color="red">mdi-clock-alert</v-icon>
                            <span class="T-size-12" v-show="item.o_name === 'CPOC'">
                              &nbsp;{{
                                $CheckWarning.wranningSafetyTraining(
                                  item.SafetyTrainingDayLeft
                                )
                              }}</span>
                          </div>
                          <div class="red--text">
                            <v-icon class="subheading T-size-18" v-show="
                              $CheckWarning.wranningMedicalCheckup(
                                item.MedicalCheckupDayLeft
                              )
                            " color="red">mdi-clock-alert</v-icon>
                            <span class="T-size-12">
                              &nbsp;{{
                                $CheckWarning.wranningMedicalCheckup(
                                  item.MedicalCheckupDayLeft
                                )
                              }}</span>
                          </div>
                          <div class="t-company T-size-12" v-if="item.o_name != null">
                            Customer : {{ item.o_name }}
                          </div>
                        </div>
                        <div class="b-action">
                          <div class="b-btn">
                            <v-btn fab class="btn-edit" @click="EditItem(item, index)" :disabled="
                              readonly_eng ||
                              readonly_pilot ||
                              readonly_shore ||
                              readonly_finance ||
                              readonly_management
                            ">
                              <span class="I-edit"></span>
                            </v-btn>
                            <v-btn fab class="primary btn-delete" @click="DeleteItem(item)" :disabled="
                              readonly_eng ||
                              readonly_pilot ||
                              readonly_shore ||
                              readonly_finance ||
                              readonly_management
                            ">
                              <span class="I-bin"></span>
                            </v-btn>
                          </div>
                        </div>
                      </div>
                      <div class="b-detail">
                        <div class="t-title">Destiantion</div>
                        <div class="box-S4 flex-between-center t-detail">
                          <div class="bL">
                            <span class="T-size-12">Departure From</span>
                            <p class="T-size-16">{{ item.departurefrom }}</p>
                          </div>
                          <div class="bC" v-if="item.checkboxinterfield == true">
                            <span class="T-size-12">Inter Field</span>
                            <i class="I-check"></i>
                          </div>
                          <div class="bR">
                            <span class="T-size-12">Destination</span>
                            <p class="T-size-16">{{ item.destination }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="b-recover">
                      <v-btn class="theme-btn-even btn-recover" @click="Recover(item, index)" :disabled="
                        readonly_eng ||
                        readonly_pilot ||
                        readonly_shore ||
                        readonly_finance ||
                        readonly_management
                      ">
                        <span class="T-size-18">Recover</span>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div v-show="listPassenger.length == 0">
                  <div class="b-nodata">
                    <div class="t-nodata">Please Select Passengers</div>
                  </div>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <!-- showAsGrid ROW -->
      <div class="box-S4" v-if="!showAsGrid">
        <v-expansion-panels accordion class="b-accod" v-model="panel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="panal-title T-size-20">Passenger List</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="wrap-passenger">
                <div class="box-4" v-for="(item, index) in listPassenger" :key="item.IdPass">
                  <div class="B-passenger" :class="item.retire" v-show="
                    item.MedicalCheckupDayLeft > '90' &&
                    item.SafetyTrainingDayLeft > '90'
                  ">
                    <div class="B-passenger TF">
                      <div class="b-person">
                        <div class="box-S1 fix-header">
                          <div v-if="
                            item.u_imageurl == undefined ||
                            item.u_imageurl == null
                          "></div>
                          <div class="B-display person1" v-else :style="{
                            backgroundImage:
                              'url(' + item.u_imageurl + ') !important',
                          }"></div>
                        </div>

                        <div class="box-S2">
                          <div class="b-name-company">
                            <p class="t-name T-size-14">{{ item.FullName }}</p>
                            <p class="T-size-16" style="color: red">
                              {{ item.p_remark }}
                            </p>
                            <div class="t-company T-size-12">
                              <span v-if="customer != undefined">{{
                                customer
                              }}</span>&nbsp;
                              <span v-if="item.c_name != undefined">[{{ item.c_name }}]</span>
                            </div>
                          </div>
                        </div>

                        <div class="box-S1 flex-between-center t-detail">
                          <div class="bC">
                            <span class="T-size-12">Departure From</span>
                            <p class="T-size-16">{{ item.departurefrom }}</p>
                          </div>
                        </div>

                        <div class="box-S1 flex-between-center t-detail">
                          <div class="bC" v-if="item.checkboxinterfield == true">
                            <span class="T-size-12">Inter Field</span>
                            <i class="I-check"></i>
                          </div>
                        </div>

                        <div class="box-S1 flex-between-center t-detail">
                          <div class="bC">
                            <span class="T-size-12">Destination</span>
                            <p class="T-size-16">{{ item.destination }}</p>
                          </div>
                        </div>

                        <div class="box-S1 flex-between-center t-detail">
                          <div class="bC">
                            <span class="T-size-12">Body Weight</span>
                            <p v-if="item.bodyweight" class="T-size-16">
                              {{ item.bodyweight }} Kg.
                            </p>
                            <p v-else class="T-size-16">-</p>
                          </div>
                        </div>

                        <div class="box-S1 flex-between-center t-detail">
                          <div class="bC">
                            <span class="T-size-12">Baggage Weight</span>
                            <p v-if="item.baggageweight != null" class="T-size-16">
                              {{ item.baggageweight }} Kg.
                            </p>
                            <p v-else class="T-size-16">-</p>
                          </div>
                        </div>

                        <div class="b-action">
                          <div class="b-btn">
                            <v-btn fab class="btn-edit" @click="EditItem(item, index)" :disabled="
                              readonly_eng ||
                              readonly_pilot ||
                              readonly_flight ||
                              readonly_shore ||
                              readonly_management
                            ">
                              <span class="I-edit"></span>
                            </v-btn>
                          </div>
                          <div class="b-btn">
                            <v-btn fab class="primary btn-delete" @click="DeleteItem(item)" :disabled="
                              readonly_eng ||
                              readonly_pilot ||
                              readonly_flight ||
                              readonly_shore ||
                              readonly_management
                            ">
                              <span class="I-bin"></span>
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="B-passenger TF" v-show="
                    item.MedicalCheckupDayLeft <= '90' ||
                    item.SafetyTrainingDayLeft <= '90'
                  ">
                    <div class="B-passenger TF">
                      <div class="b-person">
                        <div class="box-S1 fix-header">
                          <div v-if="
                            item.u_imageurl == undefined ||
                            item.u_imageurl == null
                          "></div>
                          <div class="B-display person1" v-else :style="{
                            backgroundImage:
                              'url(' + item.u_imageurl + ') !important',
                          }"></div>
                        </div>

                        <div class="box-S2">
                          <div class="b-name-company">
                            <p class="t-name T-size-14">{{ item.FullName }}</p>
                            <!-- Add show only CPOC -->
                            <div class="red--text" v-show="item.o_name === 'CPOC'">
                              <v-icon class="subheading T-size-18" v-show="
                                $CheckWarning.wranningSafetyTraining(
                                  item.SafetyTrainingDayLeft
                                )
                              " color="red">mdi-clock-alert</v-icon>
                              <span class="T-size-12">
                                &nbsp;{{
                                  $CheckWarning.wranningSafetyTraining(
                                    item.SafetyTrainingDayLeft
                                  )
                                }}</span>
                            </div>
                            <div class="red--text" v-show="item.o_name === 'CPOC'">
                              <v-icon class="subheading T-size-18" v-show="
                                $CheckWarning.wranningMedicalCheckup(
                                  item.MedicalCheckupDayLeft
                                )
                              " color="red">mdi-clock-alert</v-icon>
                              <span class="T-size-12">
                                &nbsp;{{
                                  $CheckWarning.wranningMedicalCheckup(
                                    item.MedicalCheckupDayLeft
                                  )
                                }}</span>
                            </div>
                            <p class="T-size-16" style="color: red">
                              {{ item.p_remark }}
                            </p>
                            <div class="t-company T-size-12">
                              <span v-if="customer != undefined">{{
                                customer
                              }}</span>&nbsp;
                              <span v-if="item.c_name != undefined">[{{ item.c_name }}]</span>
                            </div>
                          </div>
                        </div>

                        <div class="box-S1 flex-between-center t-detail">
                          <div class="bC">
                            <span class="T-size-12">Departure From</span>
                            <p class="T-size-16">{{ item.departurefrom }}</p>
                          </div>
                        </div>

                        <div class="box-S1 flex-between-center t-detail">
                          <div class="bC" v-if="item.checkboxinterfield == true">
                            <span class="T-size-12">Inter Field</span>
                            <i class="I-check"></i>
                          </div>
                        </div>

                        <div class="box-S1 flex-between-center t-detail">
                          <div class="bC">
                            <span class="T-size-12">Destination</span>
                            <p class="T-size-16">{{ item.destination }}</p>
                          </div>
                        </div>

                        <div class="box-S1 flex-between-center t-detail">
                          <div class="bC">
                            <span class="T-size-12">Body Weight</span>
                            <p v-if="item.bodyweight != null" class="T-size-16">
                              {{ item.bodyweight }} Kg.
                            </p>
                            <p v-else class="T-size-16">-</p>
                          </div>
                        </div>

                        <div class="box-S1 flex-between-center t-detail">
                          <div class="bC">
                            <span class="T-size-12">Baggage Weight</span>
                            <p v-if="item.baggageweight != null" class="T-size-16">
                              {{ item.baggageweight }} Kg.
                            </p>
                            <p v-else class="T-size-16">-</p>
                          </div>
                        </div>

                        <div class="b-action">
                          <div class="b-btn">
                            <v-btn fab class="btn-edit" @click="EditItem(item, index)" :disabled="
                              readonly_eng ||
                              readonly_pilot ||
                              readonly_flight ||
                              readonly_shore ||
                              readonly_management
                            ">
                              <span class="I-edit"></span>
                            </v-btn>
                          </div>
                          <div class="b-btn">
                            <v-btn fab class="primary btn-delete" @click="DeleteItem(item)" :disabled="
                              readonly_eng ||
                              readonly_pilot ||
                              readonly_flight ||
                              readonly_shore ||
                              readonly_management
                            ">
                              <span class="I-bin"></span>
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-show="listPassenger.length == 0">
                  <div class="b-nodata">
                    <div class="t-nodata">Please Select Passengers</div>
                  </div>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <!-- Revise OUT -->
      <div class="box-S4 wrap-Main B-passenger" v-if="total > 0">
        <div class="box-S4 flex-between-center">
          <div>
            <div style="margin-bottom: 10px; color: #fb0000 !important">
              <v-icon style="color: #fb0000 !important">mdi-account-alert</v-icon>
              Warning Different {{ total }} Name (Booking Now)
            </div>
          </div>
          <v-btn v-if="userdata.r_id == 4 || userdata.r_id == 5 || userdata.r_id == 21" class="right" flat
            color="primary" @click="syncDataOut()" style="margin-top: -10px">
            <v-icon>mdi-sync</v-icon>Sync Passenger
          </v-btn>
        </div>
        <div class="box-S4 noPadding">
          <v-data-table :headers="headersRevise" :items="dataCompareOut" hide-actions class="test">
            <template slot="headerCell" slot-scope="{ header }">
              <span class="subheading font-weight-light text--darken-3" v-text="header.text" />
            </template>
          </v-data-table>
        </div>
      </div>
      <!-- Revise OUT -->

      <div class="box-S1-3">
        <v-btn class="theme-btn-even MGB10" @click="AddPassenger()" v-if="
          !(
            readonly_eng ||
            readonly_pilot ||
            readonly_shore ||
            readonly_finance ||
            readonly_management
          )
        ">
          <span class="I-create"></span>
          <span>Add</span>
        </v-btn>
      </div>
      <!-- showAsGrid button -->
      <div class="box-S1">
        <v-switch v-model="showAsGrid" :label="`GRID`"> </v-switch>
      </div>

      <div class="line"></div>
    </div>
    <v-dialog v-model="dialogEvent" persistent max-width="400">
      <v-card id="dialog" class="TF type2">
        <div class="b-content">
          <div class="B-dialogform step">
            <div class="t-namepage">{{ formTitle }} Passenger</div>
            <div class="b-form">
              <v-stepper v-model="stepdialog">
                <v-stepper-header>
                  <v-stepper-step :complete="stepdialog > 1" step="1"></v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="stepdialog > 2" step="2"></v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <!-- Start step 1 select passenger -->
                  <v-stepper-content step="1">
                    <div class="b-contentstep">
                      <div class="box-btn-select" v-show="arraychoospassenger[0].FullName == ''">
                        <v-btn class="theme-btn-even btn-select" @click="OpenDialogSelectPassenger()">
                          <span class="I-create"></span>
                          <span>Select Passenger</span>
                        </v-btn>
                      </div>
                      <div class="B-passenger TF">
                        <div v-show="arraychoospassenger[0].FullName != ''">
                          <div class="b-person">
                            <div class="b-display">
                              <div class="B-display person1" :style="{
                                backgroundImage:
                                  'url(' +
                                  arraychoospassenger[0].u_imageurl +
                                  ') !important',
                              }"></div>
                            </div>
                            <div class="b-name-company">
                              <p class="t-name">
                                {{ arraychoospassenger[0].FullName }}
                              </p>
                              <div class="t-company T-size-12" v-if="customer != undefined">
                                Customer : {{ customer }}
                              </div>
                            </div>
                          </div>
                          <div class="b-detail">
                            <div class="t-title">Remark</div>
                            <div class="box-S4 flex-between-center t-detail">
                              <v-text-field label="Remark" v-model="remark"></v-text-field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="box-btn">
                      <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogEvent = false">
                        <span class="T-size-18">cancel</span>
                      </v-btn>
                      <!-- <v-btn
                        class="theme-btn-even btn-ok Bsize100"
                        @click="stepdialog = 2"
                      >
                        <span class="T-size-18">next</span>
                      </v-btn> -->
                      <!-- Edit -->
                      <v-btn class="theme-btn-even btn-ok Bsize100" @click="GotoStepDialog2()">
                        <span class="T-size-18">next</span>
                      </v-btn>
                    </div>
                  </v-stepper-content>
                  <!-- Start step 1 select passenger -->

                  <v-stepper-content step="2">
                    <div class="b-contentstep">
                      <div class="B-passenger TF">
                        <div class="b-detail">
                          <div class="t-title">Destination</div>
                          <div class="box-S4 flex-between-center">
                            <div class="box-S2 t-detail">
                              <v-select class="hideMessage" v-model="departurefrom" :items="itemsListAirport"
                                label="Departure From" item-text="ap_name" item-value="ap_id" return-object></v-select>
                            </div>
                            <div class="box-S2 t-detail">
                              <v-select class="hideMessage" v-model="destination" :items="itemsListAirport"
                                label="Destination" item-text="ap_name" item-value="ap_id" return-object></v-select>
                            </div>
                          </div>
                          <div class="b-checkbox">
                            <v-checkbox v-model="checkboxinterfield" label="Inter Field"></v-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="box-btn">
                      <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="stepdialog = 1">
                        <span class="T-size-18">cancel</span>
                      </v-btn>
                      <v-btn class="theme-btn-even btn-save Bsize100" text @click="SaveToArrayPassenger()">
                        <span class="T-size-18">Finish</span>
                      </v-btn>
                    </div>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <!-- Select Passenger -->
    <v-dialog v-model="dialogSelectPassenger" persistent max-width="800" fullscreen>
      <v-card id="dialog" class="TF type2">
        <div class="b-content">
          <div class="box-S4 flex-between-center">
            <div class="N-Page T-size-36 TF">Select Passenger</div>
            <v-btn class="theme-btn-even" @click="dialogSelectPassenger = false">
              <span>Close</span>
            </v-btn>
          </div>
          <div class="box-S4">
            <v-text-field v-model="search" class="hideMessage" label="Search by firstname"
              v-on:keyup.enter="SearchPassenger()"></v-text-field>

            <div class="b-action">
              <div class="b-btn">
                <v-btn @click="SearchPassenger()">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </div>
            </div>
          </div>

          <!-- <v-data-table :headers="headers" :search="search" :items="userItem" :items-per-page="5"> -->
          <div class="box-S4 noPadding flex-between-center">
            <v-data-table class="y-scroll" :headers="headers" :items="userItem" :items-per-page="5">
              <template v-slot:item.action="{ item }">
                <div class="b-action">
                  <div class="b-btn">
                    <v-btn fab class="btn-edit" @click="ChoosePassenger(item)"><span class="I-add2"></span></v-btn>
                  </div>
                </div>
              </template>
              <template v-slot:item.u_imageurl="{ item }">
                <img class="B-display In-table" :src="item.u_imageurl" />
              </template>
            </v-data-table>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import feathersClientUOA from "../../plugins/feathers-client-uoa";
export default {
  data: () => ({
    dataCompareOut: [],
    total: 0,
    headersRevise: [
      { value: "Status", text: "Status", sortable: true },
      { value: "Name", text: "Name", sortable: true },
      { value: "Depart", text: "Departure", sortable: true },
      { value: "Dest", text: "Destination", sortable: true },
    ],

    showAsGrid: false,

    readonly_flight: false,
    readonly_pilot: false,
    readonly_eng: false,
    readonly_shore: false,
    readonly_finance: false,

    userdata: "",
    modeEvent: "",
    dialogEvent: false,
    dialogSelectPassenger: false,
    stepdialog: 1,
    headers: [
      { value: "u_imageurl", text: "", sortable: false },
      // { value: "u_staffcode", text: "Staff Code", sortable: false },
      { value: "u_firstname", text: "First name", sortable: true },
      { value: "u_lastname", text: "Last name", sortable: true },
      // { value: "u_username", text: "Username", sortable: false },
      { value: "r_name", text: "Position", sortable: false },
      { value: "c_name", text: "Company", sortable: false },
      { value: "action", text: "", sortable: false },
    ],
    search: "",
    itemsListAirport: [],
    itemsDataPassenger: [],
    userItem: [],
    // input Model
    remark: "",

    departurefrom: "",
    departurefromname: "", //Add

    destination: "",
    destinationname: "", //Add

    checkboxinterfield: false,
    listPassenger: [],
    arraychoospassenger: [
      { c_id: "", u_imageurl: "", u_id: "", FullName: "", c_name: "" },
    ],
    //Add
    sumPassenger: 0,

    sumbodyweight: 0,
    sumbaggageweight: 0,
    sumtotal: 0,
    chooseItemPassengerIndex: null,
    chooseItemPassenger: null,
    IdPass: null,
    idflight: null,
    panel: [0],
    retire: "",

    //Add
    safetyTrainingDayLeft: 0,
    medicalCheckupDayLeft: 0,

    readonly_management: true,
  }),
  props: ["customer", "flightId"],
  components: {},
  async mounted() {
    this.userdata = JSON.parse(localStorage.getItem("user"));
    this.renderUI();
    this.SelectListAircraft();
    this.enable_disable();
  },
  computed: {
    formTitle() {
      return this.modeEvent === "create" ? "Add" : "Edit";
    },
  },
  methods: {
    async renderUI() {
      try {
        const { user } = await feathersClientUOA.get("authentication");
        // //User
        // this.search = "";
        // const q = {};
        // if(this.userdata.r_id != 4 && this.userdata.r_id != 5){
        //   //ยกเว้น flight เห็นหมดทุกคน
        //   q.o_id = this.userdata.o_id;
        // }
        // q.r_id = 3;
        // let res = await feathersClientUOA
        //   .service("viewuser")
        //   .find({ query: q });
        // this.userItem = res.data;

        //ComparePassenger out
        await this.ComparePassengerOut();
      } catch (err) { }
    },
    //Add
    OpenDialogSelectPassenger() {
      this.search = "";
      this.userItem = [];
      this.dialogSelectPassenger = true;
    },
    async enable_disable() {
      if (this.userdata.r_id == 4) {
        this.readonly_flight = false;
      } else if (this.userdata.r_id == 9) {
        this.readonly_eng = true;
      } else if (this.userdata.r_id == 12) {
        this.readonly_pilot = true;
      } else if (this.userdata.r_id == 2) {
        this.readonly_shore = true;
      } else if (this.userdata.r_id == 14) {
        this.readonly_finance = true;
      } else if (this.userdata.r_id == 15) {
        this.readonly_management = true;
      }
    },
    async SelectListAircraft() {
      try {
        var q = {};
        q.retire = "0";
        var res = await feathersClientUOA.service("airports").find({ query: q });
        this.itemsListAirport = res.data;
      } catch (error) {
        console.log(
          "SelectListAircraft ไม่สามารถขอข้อมูลจาก server ได้ /nError : " +
          error
        );
      }
    },

    async AddPassenger() {
      this.modeEvent = "create";
      this.dialogEvent = true;
      this.stepdialog = 1;
      this.arraychoospassenger = [
        {
          c_id: "",
          u_imageurl: "",
          u_id: "",
          FullName: "",
          c_name: "",
          u_estimatedweight: "",
        },
      ];
      this.remark = "";

      this.departurefrom = "";
      this.destination = "";
      //Edit set default airport
      this.departurefrom = this.itemsListAirport[0];
      this.destination = this.itemsListAirport[0];

      this.checkboxinterfield = false;
      this.bodyweight = 0;
      this.baggageweight = 0;
    },
    async EditItem(itemPass, index) {
      this.modeEvent = "edit";
      this.dialogEvent = true;
      this.stepdialog = 1;
      this.arraychoospassenger = [
        {
          u_id: itemPass.u_id,
          FullName: itemPass.FullName,
          c_name: itemPass.c_name,
          u_imageurl: itemPass.u_imageurl,
        },
      ];
      this.remark = itemPass.remark;

      //this.departurefrom = itemPass.ap_departairport_id;
      //Edit
      this.departurefrom = itemPass.ap_departairport_id;
      this.departurefromname = itemPass.departurefrom;

      // this.destination = itemPass.ap_destairport_id;
      //Edit
      this.destination = itemPass.ap_destairport_id;
      this.destinationname = itemPass.destination;

      this.checkboxinterfield = itemPass.checkboxinterfield;
      this.bodyweight = itemPass.bodyweight;
      this.baggageweight = itemPass.baggageweight;
      this.chooseItemPassengerIndex = index;

      //Add
      this.safetyTrainingDayLeft = itemPass.SafetyTrainingDayLeft;
      this.medicalCheckupDayLeft = itemPass.MedicalCheckupDayLeft;
    },
    async ChoosePassenger(dataUser) {
      this.dialogSelectPassenger = false;
      this.arraychoospassenger = [];
      this.arraychoospassenger.push(dataUser);
      this.bodyweight = dataUser.u_estimatedweight;
    },
    async SaveToArrayPassenger() {
      this.dialogEvent = false;
      if (this.modeEvent == "create") {
        //Add Save to DB
        var DataAdd = {
          u_id: this.arraychoospassenger[0].u_id,
          f_id: this.flightId,
          c_id: this.arraychoospassenger[0].c_id,
          ap_departairport_id: this.departurefrom.ap_id,
          ap_destairport_id: this.destination.ap_id,
          pt_id: 1,
          p_imageurl: this.arraychoospassenger[0].u_imageurl,

          // p_body_weight: this.bodyweight,
          // p_baggage_weight: this.baggageweight,
          p_body_weight_estimate: this.bodyweight,
          p_baggage_weight_estimate: this.baggageweight,

          p_remark: this.remark,
          p_inter_field: this.checkboxinterfield === false ? 0 : 1,
        };

        await this.CreatePassenger(DataAdd);
        this.DataPassengerFromDB(this.flightId);
      } else {
        //Add Edit to DB
        let dataUpdate = {
          c_id: this.arraychoospassenger[0].c_id,
          ap_departairport_id: this.departurefrom.ap_id,
          ap_destairport_id: this.destination.ap_id,
          p_remark: this.remark,
          p_imageurl: this.arraychoospassenger[0].u_imageurl,

          // p_body_weight: this.bodyweight,
          // p_baggage_weight: this.baggageweight,
          p_body_weight_estimate: this.bodyweight,
          p_baggage_weight_estimate: this.baggageweight,

          p_inter_field: this.checkboxinterfield === false ? 0 : 1,
          retire: 0,
        };
        this.Updatetodb(
          this.listPassenger[this.chooseItemPassengerIndex].IdPass,
          dataUpdate
        );
        this.DataPassengerFromDB(this.flightId);
      }
      this.SumDataBodyWeightPassenger();
      this.SumDataBaggageWeightPassenger();
    },
    async SumDataBodyWeightPassenger() {
      this.sumbodyweight = 0;
      this.sumPassenger = 0;

      //Add
      this.listPassenger.forEach((element) => {
        if (element.retire != "delete") {
          this.sumPassenger += 1;
        }
      });

      var weight = this.listPassenger
        .filter((x) => x.retire != "delete")
        .reduce(function (prev, cur) {
          return prev + cur.bodyweight;
        }, 0);
      this.sumbodyweight = weight;
      return this.$emit("SumDataBodyWeightOutbound", this.sumbodyweight);
    },
    async SumDataBaggageWeightPassenger() {
      this.sumbaggageweight = 0;
      var weight = this.listPassenger
        .filter((x) => x.retire != "delete")
        .reduce(function (prev, cur) {
          return prev + cur.baggageweight;
        }, 0);
      this.sumbaggageweight = weight;
      return this.$emit("SumDataBaggageWeightOutbound", this.sumbaggageweight);
    },
    async DeleteItem(itemPass) {
      //Edit
      await feathersClientUOA.service("passenger").remove(itemPass.IdPass);

      await this.DataPassengerFromDB(this.flightId);
      this.SumDataBodyWeightPassenger();
      this.SumDataBaggageWeightPassenger();
      this.ComparePassengerOut();
    },
    async CreatePassengerRawData(Id) {
      try {
        this.listPassenger.forEach((element) => {
          var DataAdd = {
            u_id: element.u_id,
            f_id: Id,
            c_id: element.c_id,
            ap_departairport_id: element.ap_departairport_id,
            ap_destairport_id: element.ap_destairport_id,
            pt_id: 1,
            p_body_weight: element.bodyweight,
            p_baggage_weight: element.baggageweight,
            remark: element.remark,
            u_imageurl: element.imgurl,
            p_inter_field: element.checkboxinterfield === true ? 0 : 1,
          };

          this.CreatePassenger(DataAdd);
        });
      } catch (error) {
        console.log(error);
      }
    },
    async CreatePassenger(DataAdd) {
      try {
        var res = await feathersClientUOA.service("passenger").create(DataAdd);
      } catch (error) {
        console.log(error);
      }
    },
    async DataPassengerFromDB(idflight) {
      this.itemsDataPassenger = [];
      this.listPassenger = [];
      try {
        var q = {};
        q.f_id = idflight;
        q.pt_id = 1;
        q.retire = 0;
        var res = await feathersClientUOA
          .service("viewflightpassenger")
          .find({ query: q });
        this.itemsDataPassenger = res.data;
        this.itemsDataPassenger.forEach((element) => {
          var datachoosepassenger = {};
          datachoosepassenger["IdPass"] = element.p_id;
          datachoosepassenger["u_id"] = element.u_id;
          datachoosepassenger["FullName"] =
            element.u_firstname + " " + element.u_lastname;
          datachoosepassenger["c_name"] = element.c_name;
          datachoosepassenger["remark"] = element.p_remark;
          datachoosepassenger["ap_departairport_id"] =
            element.ap_departairport_id;
          datachoosepassenger["departurefrom"] = element.ap_departairport_name;
          datachoosepassenger["ap_destairport_id"] = element.ap_destairport_id;
          datachoosepassenger["destination"] = element.ap_destairport_name;
          datachoosepassenger["bodyweight"] = element.p_body_weight;
          datachoosepassenger["baggageweight"] = element.p_baggage_weight;
          datachoosepassenger["c_id"] = element.c_id;
          datachoosepassenger["u_imageurl"] = element.p_imageurl;
          if (element.p_inter_field == 1) {
            datachoosepassenger["checkboxinterfield"] = true;
          } else {
            datachoosepassenger["checkboxinterfield"] = false;
          }
          datachoosepassenger["retire"] = element.retire == 1 ? "delete" : "";

          //Add
          datachoosepassenger["SafetyTrainingDayLeft"] =
            element.SafetyTrainingDayLeft;
          datachoosepassenger["MedicalCheckupDayLeft"] =
            element.MedicalCheckupDayLeft;

          datachoosepassenger["o_name"] = element.o_name;
          datachoosepassenger["p_remark"] = element.p_remark;

          this.listPassenger.push(datachoosepassenger);

          this.SumDataBodyWeightPassenger();
          this.SumDataBaggageWeightPassenger();
        });
      } catch (error) {
        console.log(error);
      }
      return this.listPassenger.length;
    },
    async DataPassengerFromBookingDB(idbook) {
      try {
        var q = {};
        q.b_id = idbook;
        q.pt_id = 1;
        q.retire = 0;
        var res = await feathersClientUOA
          .service("viewbookingpassenger")
          .find({ query: q });
        this.itemsDataPassenger = res.data;
        this.itemsDataPassenger.forEach((element) => {
          var datachoosepassenger = {};
          datachoosepassenger["tsj_id"] = null;
          datachoosepassenger["u_id"] = element.u_id;
          datachoosepassenger["FullName"] =
            element.u_firstname + " " + element.u_lastname;
          datachoosepassenger["c_name"] = element.c_name;
          datachoosepassenger["u_imageurl"] = element.u_imageurl;

          datachoosepassenger["remark"] = element.bp_remark;
          datachoosepassenger["ap_departairport_id"] =
            element.ap_departairport_id;
          datachoosepassenger["departurefrom"] = element.ap_departairport_name;
          datachoosepassenger["ap_destairport_id"] = element.ap_destairport_id;
          datachoosepassenger["destination"] = element.ap_destairport_name;
          datachoosepassenger["c_id"] = element.c_id;
          datachoosepassenger["u_telephone"] = element.u_telephone;
          datachoosepassenger["bodyweight"] = element.bp_body_weight;
          datachoosepassenger["baggageweight"] = element.bp_baggage_weight;
          this.listPassenger.push(datachoosepassenger);
          this.SumDataBodyWeightPassenger();
          this.SumDataBaggageWeightPassenger();
        });
      } catch (error) {
        console.log(error);
      }
    },
    async SavePassengerEditToDB(idflight) {
      this.idflight = idflight;
      this.listPassenger.forEach((elementUpdate) => {
        if (elementUpdate.IdPass != null) {
          let dataUpdate = {
            c_id: elementUpdate.c_id,
            ap_departairport_id: elementUpdate.ap_departairport_id,
            ap_destairport_id: elementUpdate.ap_destairport_id,
            p_remark: elementUpdate.remark,
            p_imageurl: elementUpdate.u_imageurl,
            p_body_weight: elementUpdate.bodyweight,
            p_baggage_weight: elementUpdate.baggageweight,
            p_inter_field: elementUpdate.checkboxinterfield === false ? 0 : 1,
            retire: elementUpdate.retire == "delete" ? 1 : 0,
          };

          this.Updatetodb(elementUpdate.IdPass, dataUpdate);
        } else if (elementUpdate.IdPass == null) {
          var DataAdd = {
            u_id: elementUpdate.u_id,
            f_id: this.idflight,
            c_id: elementUpdate.c_id,
            ap_departairport_id: elementUpdate.ap_departairport_id,
            ap_destairport_id: elementUpdate.ap_destairport_id,
            pt_id: 1,
            p_imageurl: elementUpdate.u_imageurl,
            p_body_weight: elementUpdate.bodyweight,
            p_baggage_weight: elementUpdate.baggageweight,
            p_remark: elementUpdate.remark,
            p_inter_field: elementUpdate.checkboxinterfield === false ? 0 : 1,
          };

          this.CreatePassenger(DataAdd);
        }
      });
    },
    async Updatetodb(IdPass, dataUpdate) {
      try {
        await feathersClientUOA.service("passenger").patch(IdPass, dataUpdate);
      } catch (error) {
        console.log(error);
      }
    },
    async Recover(itemPass, index) {
      this.modeEvent = "edit";
      this.arraychoospassenger = [
        {
          u_id: itemPass.u_id,
          FullName: itemPass.FullName,
          c_name: itemPass.c_name,
          u_imageurl: itemPass.u_imageurl,
        },
      ];
      var datachoosepassenger = {};
      datachoosepassenger["IdPass"] = itemPass.IdPass;
      datachoosepassenger["u_id"] = itemPass.u_id;
      datachoosepassenger["FullName"] = itemPass.FullName;
      datachoosepassenger["c_name"] = itemPass.c_name;
      datachoosepassenger["u_imageurl"] = itemPass.u_imageurl;
      datachoosepassenger["remark"] = itemPass.remark;
      datachoosepassenger["ap_departairport_id"] = itemPass.ap_departairport_id;
      datachoosepassenger["departurefrom"] = itemPass.departurefrom;
      datachoosepassenger["ap_destairport_id"] = itemPass.ap_destairport_id;
      datachoosepassenger["destination"] = itemPass.destination;
      datachoosepassenger["checkboxinterfield"] = itemPass.checkboxinterfield;
      datachoosepassenger["bodyweight"] = itemPass.bodyweight;
      datachoosepassenger["baggageweight"] = itemPass.baggageweight;
      datachoosepassenger["retire"] = 0;

      //Add
      datachoosepassenger["SafetyTrainingDayLeft"] =
        itemPass.SafetyTrainingDayLeft;
      datachoosepassenger["MedicalCheckupDayLeft"] =
        itemPass.MedicalCheckupDayLeft;

      this.listPassenger[index] = datachoosepassenger;

      this.SumDataBodyWeightPassenger();
      this.SumDataBaggageWeightPassenger();
    },

    //Add
    GotoStepDialog2() {
      if (this.arraychoospassenger[0].FullName != "") {
        this.stepdialog = 2;
      }
    },

    async ComparePassengerOut() {
      try {
        let a = await feathersClientUOA
          .service("flightcompareservice")
          .patch("ComparePassenger", { Id: this.flightId, PassengerType: 1 });
        this.dataCompareOut = a[0].datapassenger.filter(
          (x) => x.PassengerTypeId == 1
        );
        this.total = this.dataCompareOut.length;
      } catch (err) {
        //alert("ไม่สามารถต่อ server ได้ Compare"+err);
      }
    },

    async syncDataOut() {
      try {
        await feathersClientUOA
          .service("flightcompareservice")
          .patch("SyncOutPassenger", { Id: this.flightId });
        this.DataPassengerFromDB(this.flightId);
        this.ComparePassengerOut();
      } catch (err) {
        console.log(err);
        this.$toast.error("Cannot delete data " + err);
      }
    },

    async SearchPassenger() {
      try {
        if (this.search != "") {
          const q = {};
          if (this.userdata.r_id != 4 && this.userdata.r_id != 5 && this.userdata.r_id != 21) {
            //ยกเว้น flight เห็นหมดทุกคน
            q.o_id = this.userdata.o_id;
          }
          q.r_id = 3;
          q.u_firstname = { $like: this.search + "%" };

          let res = await feathersClientUOA
            .service("viewuser")
            .find({ query: q });
          this.userItem = res.data;
        }
      } catch (err) { }
    },
  },
};
</script>

<style >

</style>