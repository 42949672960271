<template>
  <div>
    <div id="outPassenger" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <div class="N-Page T-size-24">Revise</div>
        <v-btn
          class="theme-btn-even MGB10"
          @click="AddRevise()"
          v-if="
            !(
              readonly_eng ||
              readonly_pilot ||
              readonly_shore ||
              readonly_finance
            )
          "
        >
          <span class="I-create"></span>
          <span>Add Revise</span>
        </v-btn>
      </div>

      <!-- showAsGrid ROW -->
      <div class="box-S4">
        <v-expansion-panels accordion class="b-accod" v-model="panel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="panal-title T-size-20">Revise List</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="wrap-passenger">
                <div
                  class="box-4"
                  v-for="(item, index) in listRevise"
                  :key="item.IdPass"
                >
                  <div class="B-passenger TF">
                    <div class="B-passenger TF">
                      <div class="b-person">
                        <div class="box-S4 flex-between-center t-detail">
                          <div class="bL">
                            <span class="T-size-12">Revise No</span>
                            <p class="T-size-16">{{ item.bh_revise_no }}</p>
                          </div>
                        </div>

                        <div class="box-S4 flex-between-center t-detail">
                          <div class="bL">
                            <span class="T-size-12">Action</span>
                            <p class="T-size-16">{{ item.bha_name }}</p>
                          </div>
                        </div>

                        <div class="box-S4 flex-between-center t-detail">
                          <div class="bL">
                            <span class="T-size-12">Previous</span>
                            <p
                              v-if="item.bh_previous_data != null"
                              class="T-size-16"
                            >
                              {{ item.bh_previous_data }}
                            </p>
                            <p v-else class="T-size-16">-</p>
                          </div>
                        </div>

                        <div class="box-S4 flex-between-center t-detail">
                          <div class="bL">
                            <span class="T-size-12">Current</span>
                            <p class="T-size-16">{{ item.bh_current_data }}</p>
                          </div>
                        </div>

                        <div class="box-S4 flex-between-center t-detail">
                          <div class="bL">
                            <span class="T-size-12">Change By</span>
                            <p class="T-size-16">{{ item.bh_change_by }}</p>
                          </div>
                        </div>

                        <div class="box-S4 flex-between-center t-detail">
                          <div class="bL">
                            <span class="T-size-12">On Behalf</span>
                            <p
                              v-if="item.bh_on_behalf != null"
                              class="T-size-16"
                            >
                              {{ item.bh_on_behalf }}
                            </p>
                            <p v-else class="T-size-16">-</p>
                          </div>
                        </div>
                        <div class="box-S4 flex-between-center t-detail">
                          <div class="bL">
                            <span class="T-size-12">Create Date</span>
                            <p class="T-size-16">{{ item.bh_createdate }}</p>
                          </div>
                        </div>

                        <div class="b-action">
                          <div class="b-btn">
                            <v-btn
                              fab
                              class="primary btn-delete"
                              @click="DeleteItem(item, index)"
                              :disabled="
                                readonly_eng ||
                                readonly_pilot ||
                                readonly_flight
                              "
                            >
                              <span class="I-bin"></span>
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <div class="line"></div>
    </div>

    <v-dialog v-model="dialogEvent" persistent max-width="400">
      <v-card id="dialog" class="TF type3">
        <div class="b-content">
          <div class="B-dialogform">
            <div class="t-namepage">Add Revise</div>

            <!-- Revise No -->
            <v-select
              class="hideMessage"
              :items="itemsListReviseNo"
              item-text="revise_name"
              item-value="revise_id"
              label="Revise No"
              v-model="reviseNo"
              return-object
            ></v-select>

            <!-- Action -->
            <v-select
              class="hideMessage"
              :items="itemsListAction"
              item-text="bha_name"
              item-value="bha_id"
              label="Action"
              v-model="action"
              return-object
              @change="SelectAction()"
            ></v-select>

            <!-- Change Departure Date -->
            <div
              class="box-S4 flex-between-center noPadding"
              v-show="departDateIsShow"
            >
              <div class="box-S2">
                <v-menu
                  disabled="true"
                  v-model="menuselect_depart_date"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      class="hideMessage input-date"
                      v-model="depart_date"
                      label="Departure Date"
                      readonly
                      v-on="on"
                      append-icon="mdi-calendar-range"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="depart_date"
                    :readonly="
                      readonly_eng ||
                      readonly_pilot ||
                      readonly_shore ||
                      readonly_finance
                    "
                    @input="menuselect_depart_date = false"
                  ></v-date-picker>
                </v-menu>
              </div>

              <div class="box-S2">
                <v-text-field
                  label="Departure Time"
                  :readonly="
                    readonly_eng ||
                    readonly_pilot ||
                    readonly_shore ||
                    readonly_finance
                  "
                  v-model="depart_time"
                  type="time"
                ></v-text-field>
              </div>
            </div>

            <!-- Change Destination Date -->
            <div
              class="box-S4 flex-between-center noPadding"
              v-show="destDateIsShow"
            >
              <div class="box-S2">
                <v-menu
                  disabled="true"
                  v-model="menuselect_dest_date"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      class="hideMessage input-date"
                      v-model="dest_date"
                      label="Destination Date"
                      readonly
                      v-on="on"
                      append-icon="mdi-calendar-range"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dest_date"
                    :readonly="
                      readonly_eng ||
                      readonly_pilot ||
                      readonly_shore ||
                      readonly_finance
                    "
                    @input="menuselect_dest_date = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="box-S2">
                <v-text-field
                  label="Destination Time"
                  v-model="dest_time"
                  :readonly="
                    readonly_eng ||
                    readonly_pilot ||
                    readonly_shore ||
                    readonly_finance
                  "
                  type="time"
                ></v-text-field>
              </div>
            </div>

            <!-- Change Aircraft -->
            <v-select
              v-show="aircraftIsShow"
              class="hideMessage"
              :items="itemsListAircraft"
              item-text="ac_name"
              item-value="ac_id"
              label="Aircraft"
              v-model="aircraft"
              return-object
            ></v-select>

            <!-- Change PILOT -->
            <v-select
              v-show="pilotIsShow"
              class="hideMessage"
              :items="itemsPilot"
              item-text="pl_name"
              item-value="pl_id"
              label="Pilot"
              v-model="pilot"
              return-object
            >
            </v-select>

            <!-- Change CO PILOT -->
            <v-select
              v-show="copilotIsShow"
              class="hideMessage"
              :items="itemsCoPilot"
              item-text="pl_name"
              item-value="pl_id"
              label="Co-Pilot"
              v-model="copilot"
              return-object
            ></v-select>

            <!-- On Behalf -->
            <v-select
              class="hideMessage"
              v-show="onBehalfIsShow"
              :items="itemsListOnBehalf"
              item-text="u_fullname"
              item-value="u_id"
              label="On Behalf"
              v-model="onBehalf"
              return-object
            ></v-select>

            <!-- Email v-textarea-->
            <v-text-field
              label="Email"
              v-model="email"
              :readonly="
                readonly_eng ||
                readonly_flight ||
                readonly_shore ||
                readonly_finance ||
                readonly_checkin
              "
            >
            </v-text-field>
          </div>

          <div class="box-btn">
            <v-btn
              class="theme-btn-even btn-cancel Bsize100"
              text
              @click="dialogEvent = false"
            >
              <span class="T-size-18">cancel</span>
            </v-btn>
            <v-btn
              class="theme-btn-even btn-save Bsize100"
              text
              @click="SaveRevise()"
            >
              <span class="T-size-18">save</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Add -->
    <v-dialog v-model="dialogSaveComlete" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog confirm">
            <div class="t-title">Save Completed</div>
            <!-- <div class="t-des">
              Save Data Complete
              <br />You want to Create Transport?
              <v-btn
                class="theme-btn-even btn-transport center Bsize190"
                @click="GotoCreateTransport()"
              >
                <span class="I-transport"></span>
                <span class="T-size-18">Create Transport</span>
              </v-btn>
            </div> -->
          </div>
          <div class="box-btn">
            <v-btn
              class="theme-btn-even btn-ok Bsize100"
              text
              @click="SaveCompleted()"
            >
              <span class="T-size-18">OK</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import feathersClientUOA from "../../plugins/feathers-client-uoa";
import { format, addHours } from "date-fns";

export default {
  data: () => ({
    dataCompareOut: [],
    total: 0,
    headersRevise: [
      { value: "Status", text: "Status", sortable: true },
      { value: "Name", text: "Name", sortable: true },
      { value: "Depart", text: "Departure", sortable: true },
      { value: "Dest", text: "Destination", sortable: true },
    ],

    readonly_flight: false,
    readonly_pilot: false,
    readonly_eng: false,
    readonly_shore: false,
    readonly_finance: false,
    readonly_checkin: false,

    userdata: "",
    modeEvent: "",
    dialogEvent: false,
    dialogSelectPassenger: false,
    stepdialog: 1,
    headers: [
      { value: "u_imageurl", text: "", sortable: false },
      // { value: "u_staffcode", text: "Staff Code", sortable: false },
      { value: "u_firstname", text: "First name", sortable: true },
      { value: "u_lastname", text: "Last name", sortable: true },
      // { value: "u_username", text: "Username", sortable: false },
      { value: "r_name", text: "Position", sortable: false },
      { value: "c_name", text: "Company", sortable: false },
      { value: "action", text: "", sortable: false },
    ],
    search: "",

    itemsDataRevise: [],
    userItem: [],
    // input Model
    remark: "",

    departurefrom: "",
    departurefromname: "", //Add

    destination: "",
    destinationname: "", //Add

    checkboxinterfield: false,

    arraychoospassenger: [
      { c_id: "", u_imageurl: "", u_id: "", FullName: "", c_name: "" },
    ],
    //Add
    sumRevise: 0,

    sumbodyweight: 0,
    sumbaggageweight: 0,
    sumtotal: 0,
    chooseItemPassengerIndex: null,
    chooseItemPassenger: null,
    IdPass: null,
    idflight: null,
    panel: [0],
    retire: "",

    //Add
    safetyTrainingDayLeft: 0,
    medicalCheckupDayLeft: 0,

    listRevise: [],

    reviseNo: 1,
    itemsListReviseNo: [],

    menuselect_depart_date: false,
    menuselect_depart_time: false,
    menuselect_dest_date: false,
    menuselect_dest_time: false,

    action: null,
    itemsListAction: [],

    departDateIsShow: false,
    depart_date: "",
    depart_time: "",

    destDateIsShow: false,
    dest_date: "",
    dest_time: "",

    aircraftIsShow: false,
    aircraft: null,
    itemsListAircraft: [],

    itemsListPilot: [],

    pilotIsShow: false,
    pilot: null,
    itemsPilot: [],

    copilotIsShow: false,
    copilot: null,
    itemsCoPilot: [],

    onBehalfIsShow: false,
    onBehalf: null,
    itemsListOnBehalf: [],

    email: null,

    dialogSaveComlete: false,

    //master: [],
  }),
  props: ["customer", "flightId", "master"],
  components: {},
  async mounted() {
    this.userdata = JSON.parse(localStorage.getItem("user"));
    this.DataFlightFromDB();
    this.renderUI();
    this.enable_disable();
  },
  computed: {
    formTitle() {
      return this.modeEvent === "create" ? "Add" : "Edit";
    },
  },
  methods: {
    async DataFlightFromDB() {
      this.divFD = this.f_id === null ? "status FD" : "status FD active";
      this.divEN = this.aircraft === null ? "status EN" : "status EN active";
      this.divPI =
        (this.pilot === "" && this.copilot === "") ||
        (this.pilot === null && this.copilot === null)
          ? "status PI"
          : "status PI active";
      //this.divCTE = this.aircraft !== "" && ((this.pilot === "" && this.copilot === "")||(this.pilot === null && this.copilot === null))  ? "status CTE active" : "status CTE "
      this.divCTE =
        this.divFD == "status FD active" &&
        this.divEN == "status EN active" &&
        this.divPI == "status PI active"
          ? "status CTE active"
          : "status CTE ";
    },

    async DataReviseFromDB(idflight) {
      this.itemsDataRevise = [];
      this.listRevise = [];
      try {
        var q = {};
        q.f_id = idflight;
        //น้อยไปมาก
        q.$sort = {
          bh_revise_no: 1,
        };
        var res = await feathersClientUOA
          .service("onbehalf")
          .find({ query: q });
        this.itemsDataRevise = res.data;
        this.itemsDataRevise.forEach((element) => {
          var dataRevise = {};
          dataRevise["IdPass"] = element.bh_id;
          dataRevise["bh_revise_no"] = element.bh_revise_no;
          dataRevise["bh_previous_id"] = element.bh_previous_id;
          dataRevise["bh_previous_data"] = element.bh_previous_data;
          dataRevise["bh_current_id"] = element.bh_current_id;
          dataRevise["bh_current_data"] = element.bh_current_data;
          dataRevise["bh_change_by"] = element.bh_change_by;
          dataRevise["bh_on_behalf"] = element.bh_on_behalf;
          let date = element.bh_createdate.substring(0, 10);
          let time = element.bh_createdate.substring(11, 16);
          dataRevise["bh_createdate"] = date + " " + time;
          dataRevise["bha_id"] = element.bha_id;
          dataRevise["bha_name"] = element.bha_name;
          dataRevise["f_id"] = element.f_id;

          this.listRevise.push(dataRevise);
        });

        this.sumRevise = this.listRevise.length;
      } catch (error) {
        console.log(error);
      }
      return this.listRevise.length;
    },

    async renderUI() {
      try {
        const { user } = await feathersClientUOA.get("authentication");
      } catch (err) {}
    },

    SelectListReviseNo() {
      for (let index = 1; index <= 10; index++) {
        this.itemsListReviseNo.push({ revise_id: index, revise_name: index });
      }
      this.reviseNo = this.itemsListReviseNo[0];
    },

    SelectAction() {
      if (this.action.bha_id == 1) {
        this.departDateIsShow = true;
        this.destDateIsShow = false;
        this.aircraftIsShow = false;
        this.pilotIsShow = false;
        this.copilotIsShow = false;
        this.onBehalfIsShow = false;
      }
      if (this.action.bha_id == 2) {
        this.departDateIsShow = false;
        this.destDateIsShow = true;
        this.aircraftIsShow = false;
        this.pilotIsShow = false;
        this.copilotIsShow = false;
        this.onBehalfIsShow = false;
      }
      if (this.action.bha_id == 3) {
        this.departDateIsShow = false;
        this.destDateIsShow = false;
        this.aircraftIsShow = true;
        this.pilotIsShow = false;
        this.copilotIsShow = false;
        this.onBehalfIsShow = true;
      }
      if (this.action.bha_id == 4) {
        this.departDateIsShow = false;
        this.destDateIsShow = false;
        this.aircraftIsShow = false;
        this.pilotIsShow = true;
        this.copilotIsShow = false;
        this.onBehalfIsShow = true;
      }
      if (this.action.bha_id == 5) {
        this.departDateIsShow = false;
        this.destDateIsShow = false;
        this.aircraftIsShow = false;
        this.pilotIsShow = false;
        this.copilotIsShow = true;
        this.onBehalfIsShow = true;
      }
    },

    async SelectListOnBehalfActoion() {
      try {
        var res = await feathersClientUOA.service("onbehalfaction").find({});

        this.itemsListAction = res.data;
        this.action = this.itemsListAction[0];
      } catch (error) {
        console.log("SelectListOnBehalfActoion ไม่สามารถขอข้อมูลจาก server ได้ /nError : " + error);
      }
    },

    async SelectListAircraft() {
      try {
        this.itemsListAircraft = this.master.aircraft;
        this.aircraft = this.master.aircraft[0];
      } catch (error) {
        console.log("SelectListAircraft ไม่สามารถขอข้อมูลจาก server ได้ /nError : " + error);
      }
    },

    async SelectListPilotAndCoPilot() {
      try {
        this.itemsPilot = this.master.pilot;
        this.itemsCoPilot = this.master.pilot;

        for (let index = 0; index < this.itemsPilot.length; index++) {
          const elementId = this.itemsPilot[index].pl_id;
          const element = this.itemsPilot[index].pl_name;
          this.itemsListPilot.push({ pl_id: elementId, pl_name: element });
        }
      } catch (error) {
        console.log("SelectListPilotAndCoPilot ไม่สามารถขอข้อมูลจาก server ได้ /nError : " + error);
      }
    },

    async SelectListOnBehalf() {
      try {
        var res = await feathersClientUOA.service("account").find({});

        var list = res.data;
        list.forEach((element) => {
          var newData = {};
          newData["u_id"] = element.u_id;
          newData["u_fullname"] =
            element.u_firstname + "  " + element.u_lastname;
          this.itemsListOnBehalf.push(newData);
        });
        this.onBehalf = this.itemsListOnBehalf[0];
      } catch (error) {
        console.log("SelectListOnBehalf ไม่สามารถขอข้อมูลจาก server ได้ /nError : " + error);
      }
    },

    //Add
    async enable_disable() {
      if (this.userdata.r_id == 4) {
        this.readonly_flight = false;
      } else if (this.userdata.r_id == 9) {
        this.readonly_eng = true;
      } else if (this.userdata.r_id == 12) {
        this.readonly_pilot = true;
      } else if (this.userdata.r_id == 2) {
        this.readonly_shore = true;
      } else if (this.userdata.r_id == 14) {
        this.readonly_finance = true;
      }
    },

    async AddRevise() {
      this.SelectListReviseNo();
      this.SelectListOnBehalfActoion();
      this.SelectListAircraft();
      this.SelectListPilotAndCoPilot();
      this.SelectListOnBehalf();

      var q = {};
      q.f_id = this.flightId;
      q.$eager = "[pilot, copilot, customer, aircrafts]";
      var res = await feathersClientUOA.service("flight").find({ query: q });
      this.itemsDataFlight = res.data[0];

      this.depart_date = this.itemsDataFlight.ap_depart_date.substring(0, 10);
      this.depart_time = this.itemsDataFlight.ap_depart_date.substring(11, 16);

      this.dest_date = this.itemsDataFlight.ap_dest_date.substring(0, 10);
      this.dest_time = this.itemsDataFlight.ap_dest_date.substring(11, 16);

      if (this.itemsDataFlight.ac_id != null) {
        this.aircraft = {
          ac_id: this.itemsDataFlight.aircrafts.ac_id,
          ac_name: this.itemsDataFlight.aircrafts.ac_name,
        };
        this.aircraftBackup = this.itemsDataFlight.f_aircraft_backup;
      }

      if (this.itemsDataFlight.f_pilot_id != null) {
        this.pilot = {
          pl_id: this.itemsDataFlight.f_pilot_id,
          pl_name: this.itemsDataFlight.f_pilot_name,
        };
      }
      if (this.itemsDataFlight.f_copilot_id != null) {
        this.copilot = {
          pl_id: this.itemsDataFlight.f_copilot_id,
          pl_name: this.itemsDataFlight.f_copilot_name,
        };
      }

      this.action.bha_id = 1;

      this.depart_date = this.itemsDataFlight.ap_depart_date.substring(0, 10);
      this.depart_time = this.itemsDataFlight.ap_depart_date.substring(11, 16);

      this.dest_date = this.itemsDataFlight.ap_dest_date.substring(0, 10);
      this.dest_time = this.itemsDataFlight.ap_dest_date.substring(11, 16);

      this.aircraft = this.itemsDataFlight.ac_id;
      this.pilot = this.itemsDataFlight.f_pilot_id;
      this.copilot = this.itemsDataFlight.f_copilot_id;

      this.onBehalf = null;
      this.email = null;

      this.SelectAction();
      this.dialogEvent = true;
    },

    async SaveRevise() {
      var previousDataID = "";
      var previousData = "";
      var onbehalf = "";
      var onbehalfDataID = "";
      var onbehalfData = "";

      if (this.onBehalf != null) {
        onbehalf = this.onBehalf.u_fullname;
      }

      try {
        if (this.email != null && this.email != "") {
          if (this.action.bha_id == 1) {
            previousDataID = null;
            previousData =
              this.itemsDataFlight.ap_depart_date.substring(0, 10) +
              " " +
              this.itemsDataFlight.ap_depart_date.substring(11, 16);
            onbehalfDataID = null;
            onbehalfData = this.depart_date + " " + this.depart_time;
            onbehalf = null;
            var dataUpdate = {
              ap_depart_date: this.depart_date + " " + this.depart_time,
            };
            await feathersClientUOA
              .service("flight")
              .patch(this.flightId, dataUpdate);
          }

          if (this.action.bha_id == 2) {
            previousDataID = null;
            previousData =
              this.itemsDataFlight.ap_dest_date.substring(0, 10) +
              " " +
              this.itemsDataFlight.ap_dest_date.substring(11, 16);
            onbehalfDataID = null;
            onbehalfData = this.dest_date + " " + this.dest_time;
            onbehalf = null;
            var dataUpdate = {
              ap_dest_date: this.dest_date + " " + this.dest_time,
            };
            await feathersClientUOA
              .service("flight")
              .patch(this.flightId, dataUpdate);
          }

          if (this.action.bha_id == 3) {
            if (this.aircraft != null && this.onBehalf != null) {
              let ac_id = null;
              let ac_name = null;
              if (this.itemsDataFlight.ac_id != null) {
                ac_id = this.itemsDataFlight.ac_id;
                ac_name = this.itemsDataFlight.aircrafts.ac_name;
              }
              previousDataID = ac_id;
              previousData = ac_name;
              onbehalfDataID = this.aircraft.ac_id;
              onbehalfData = this.aircraft.ac_name;
              var DataAdd = {
                ac_id: this.aircraft.ac_id,
              };
              await feathersClientUOA
                .service("flight")
                .patch(this.flightId, DataAdd);
            } else {
              return;
            }
          }

          if (this.action.bha_id == 4) {
            if (this.pilot != null && this.onBehalf != null) {
              previousDataID = this.itemsDataFlight.f_pilot_id;
              previousData = this.itemsDataFlight.f_pilot_name;

              onbehalfDataID = this.pilot.pl_id;
              onbehalfData = this.pilot.pl_name;
              let pilotId = null;
              let pilotName = null;
              if (this.pilot != null) {
                pilotId = this.pilot.pl_id;
                pilotName = this.pilot.pl_name;
              }
              var dataUpdate = {
                f_pilot_id: pilotId,
                f_pilot_name: pilotName,
              };
              await feathersClientUOA
                .service("flight")
                .patch(this.flightId, dataUpdate);
            } else {
              return;
            }
          }

          if (this.action.bha_id == 5) {
            if (this.copilot != null && this.onBehalf != null) {
              previousDataID = this.itemsDataFlight.f_copilot_id;
              previousData = this.itemsDataFlight.f_copilot_name;
              onbehalfDataID = this.copilot.pl_id;
              onbehalfData = this.copilot.pl_name;
              let coPilotId = null;
              let coPilotName = null;
              if (this.copilot != null) {
                coPilotId = this.copilot.pl_id;
                coPilotName = this.copilot.pl_name;
              }
              var dataUpdate = {
                f_copilot_id: coPilotId,
                f_copilot_name: coPilotName,
              };
              await feathersClientUOA
                .service("flight")
                .patch(this.flightId, dataUpdate);
            } else {
              return;
            }
          }

          var onBehalfDataAdd = {
            bh_revise_no: this.reviseNo.revise_id,
            bh_previous_id: previousDataID,
            bh_previous_data: previousData,
            bh_current_id: onbehalfDataID,
            bh_current_data: onbehalfData,
            bh_change_by: this.userdata.FullName,
            bh_on_behalf: onbehalf,
            bh_createdate: format(new Date(), "yyyy-MMM-dd HH:mm:ss"),
            bha_id: this.action.bha_id,
            bha_name: this.action.bha_name,
            bh_email_alert: this.email,
            f_id: this.flightId,
          };

          let res = await feathersClientUOA
            .service("onbehalf")
            .create(onBehalfDataAdd);

          let data = {
            onBehalfID: res.bh_id,
            emails: this.email,
          };
          await feathersClientUOA
            .service("emailonbehalfcommand")
            .patch(res.bh_id, data);

          this.dialogEvent = false;
          this.dialogSaveComlete = true;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async SaveCompleted() {
      this.dialogSaveComlete = false;
      return this.$emit("ReviseRenderFlight", null);
    },

    async DeleteItem(itemPass, index) {
      //Edit
      await feathersClientUOA.service("onbehalf").remove(itemPass.IdPass);
      await this.DataReviseFromDB(this.flightId);
    },
  },
};
</script>

<style>
</style>